import React, { useContext, useEffect, useState } from "react";
import Alert from "../component/Alert";
import Button from "../component/Button";
import MaterialContext from "../context/material/materialContext";
import Spinner from "../component/Spinner";
import { Link } from "react-router-dom";
import ProductionContext from "../context/production/productionContext";
import TypeSelector from "../component/TypeSelector";
import GradeSelector from "../component/GradeSelector";
import Modal from "../component/Modal";
import Memo from "./Memo";
import Confirmation from "../component/Confirmation";

const ProductDetails = props => {
  const materialContext = useContext(MaterialContext);
  const productionContext = useContext(ProductionContext);

  const { confirmProduct, loading, deleteProduct } =
    productionContext;

  const currentMaterial = JSON.parse(localStorage.getItem("currentMaterial"));
  const currentProduct = JSON.parse(localStorage.getItem("currentProduct"));

  // materialContext.setType(currentMaterial.category);

  const [productData, setProductData] = useState({
    thickness: currentMaterial.thickness,
    width: currentMaterial.width,
    grade: currentMaterial.grade,
    type: currentMaterial.category,
    diameter: currentMaterial.diameter,
  });

  const { thickness, width, grade, type, diameter } = productData;

  const [joint, setJoint] = useState(false);

  useEffect(() => {
    materialContext.type.name !== currentMaterial.category.name &&
      handleTypeOpen();
    // eslint-disable-next-line
  }, [materialContext.type.name]);

  useEffect(() => {
    materialContext.grade.name !== currentMaterial.grade.name &&
      handleGradeOpen();
    // eslint-disable-next-line
  }, [materialContext.grade.name]);

  const onChange = e =>
    setProductData({ ...productData, [e.target.name]: e.target.value });

  const productConfirmSubmit = async () => {
    // console.log('confirm submit');
    await confirmProduct(
      currentMaterial.id,
      currentProduct.id,
      grade,
      type,
      thickness,
      width,
      diameter
    );
  };

  // joint modal
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // grade change modal
  const [openGrade, setGradeOpen] = useState(false);

  const handleGradeOpen = () => setGradeOpen(true);
  const handleGradeClose = () => setGradeOpen(false);

  // type change modal
  const [openType, setTypeOpen] = useState(false);

  const handleTypeOpen = () => setTypeOpen(true);
  const handleTypeClose = () => setTypeOpen(false);

  // delete confirmation modal
  const [openDelete, setDeleteOpen] = useState(false);

  const handleDeleteOpen = () => setDeleteOpen(true);
  const handleDeleteClose = () => setDeleteOpen(false);

  // delete product
  const deleteProductSubmit = async () => {
    await deleteProduct(currentMaterial.id, currentProduct.id);

    await props.history.push("/production/cut");
  };

  return (
    <>
      <Alert />
      <div className="container">
        {loading ? (
          <Spinner />
        ) : (
          <div className="production">
            <div className="text-center">
              <div>
                <Button text={currentProduct.name} className="btn btn-prod" />
              </div>
              <div>
                <GradeSelector proc="prod" />
                <TypeSelector proc="prod" />
                {joint ? (
                  <Button
                    text="Joints"
                    className="btn btn-prod"
                    icon={<i className="fa-solid fa-triangle-exclamation"></i>}
                    onClick={handleOpen}
                  />
                ) : (
                  <Button
                    text="No joints"
                    className="btn btn-prod"
                    onClick={handleOpen}
                  />
                )}

                <div className="input-group">
                  <span className="input-fix-left">Thickness</span>
                  <input
                    className="input-fix"
                    type="number"
                    name="thickness"
                    value={thickness}
                    onChange={onChange}
                  />
                  <span className="input-fix-right">mm</span>
                </div>
                <div className="input-group">
                  <span className="input-fix-left">Width</span>
                  <input
                    className="input-fix"
                    type="number"
                    name="width"
                    value={width}
                    onChange={onChange}
                  />
                  <span className="input-fix-right">mm</span>
                </div>
                {type.name === "steel" ? (
                  <div className="input-group">
                    <span className="input-fix-left">Diameter</span>
                    <input
                      className="input-fix"
                      type="number"
                      name="diameter"
                      value={diameter}
                      onChange={onChange}
                    />
                    <span className="input-fix-right">mm</span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="text-center">
              <div>
                <Button
                  text="Delete"
                  className="btn btn-danger"
                  onClick={handleDeleteOpen}
                />
              </div>
              <div>
                <Button
                  text="Confirm"
                  className="btn btn-prod"
                  onClick={async () => {
                    await productConfirmSubmit();
                    props.history.push("/production/cut");
                  }}
                />
              </div>
              <Link to="/production/cut">
                <Button text="Back" className="btn btn-prod" />
              </Link>
            </div>
          </div>
        )}
      </div>
      <Modal
        open={openDelete}
        component={
          <Confirmation
            msg={`${currentProduct.name} will be deleted, Do you wish to proceed?`}
            onConfirm={() => deleteProductSubmit()}
            onCancel={handleDeleteClose}
          />
        }
      />
      <Modal
        open={open}
        component={
          <Memo
            placeholder="Material ID"
            proc="prod"
            title={currentProduct.name}
            memoTitle="Please specifiy joint Material ID"
            id={currentProduct.id}
            type="Product"
            preContent="Joint"
            isCamera={true}
            onSubmit={()=>setJoint(true)}
            onClose={handleClose}
          />
        }
      />
      <Modal
        open={openGrade}
        component={
          <Memo
            placeholder="Reason for changing Grade"
            proc="prod"
            title={currentProduct.name}
            memoTitle="Please specify the reason for changing grade, and attach photo(s)"
            id={currentProduct.id}
            type="Product"
            isCamera={true}
            onSubmit={() => console.log("submit grade change")}
            onClose={handleGradeClose}
          />
        }
      />
      <Modal
        open={openType}
        component={
          <>
            <Memo
              placeholder="Reason for changing Type"
              proc="prod"
              title={currentProduct.name}
              memoTitle="Please specify the reason for changing material type, and attach photo(s)"
              id={currentProduct.id}
              type="Product"
              isCamera={true}
              onSubmit={() => console.log("submit type change")}
              onClose={handleTypeClose}
            />
            <Button text="Cancel" className="btn btn-prod" />
          </>
        }
      />
    </>
  );
};

export default ProductDetails;
