import React, { useContext, useState } from "react";
import MemoContext from "../context/memo/memoContext";
import ImagesContext from "../context/images/imagesContext";
import CameraMemo from "../component/CameraMemo";
import Button from "../component/Button";

const Memo = props => {
  const memoContext = useContext(MemoContext);
  const imagesContext = useContext(ImagesContext);
  const { setMemoImages, memoImages } = imagesContext;

  const { createMemo } = memoContext;

  const {
    title,
    memoTitle,
    proc,
    placeholder,
    isCamera,
    id,
    type,
    preContent,
    onSubmit,
    onClose,
  } = props;

  let pre = preContent? preContent+": ": '';

  const [content, setContent] = useState("");

  const onChange = e => setContent(e.target.value);

  return (
    <>
      <div
        className="container"
        style={{ backgroundColor: "#fff", height: "100vh" }}
      >
        <div className="production">
          <div>
            <Button text={title} className={`btn btn-${proc}`} />
          </div>
          <div>{memoTitle}</div>
          <div className="form-control">
            <input
              className="input-text"
              name="content"
              type="text"
              placeholder={placeholder}
              value={content}
              onChange={onChange}
            />
          </div>
          {isCamera && <CameraMemo setMemoImages={setMemoImages} proc={proc} />}
          <div>
            {!content ? (
              <Button
                text="Submit"
                className={`btn btn-${proc} btn-disabled`}
              />
            ) : (
              <Button
                text="Submit"
                className={`btn btn-${proc}`}
                onClick={() => {
                  createMemo(id, type, pre+content, memoImages, () =>
                    setMemoImages([])
                  );
                  onSubmit();
                  onClose();
                }}
              />
            )}
            <Button
              text="Back"
              className={`btn btn-${proc}`}
              onClick={() => onClose()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Memo;
